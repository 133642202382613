import * as React from "react"
import "../styles/index.scss"
import profilePic from "../images/takitaProfilePic.png"

import illust1 from "../images/works/illust1.webp"
import illust2 from "../images/works/illust2.webp"
import illust3 from "../images/works/illust3.webp"
import illust4 from "../images/works/illust4.webp"
import illust5 from "../images/works/illust5.webp"

// MarkUp
const IndexPage = () =>{

  return (
    <main>
      <title>ポーョスッポロチャーモ</title>

    
      <div className="hero-container">
        <div className="main-hero">
          <h1 className="hero glitch layers" data-text="ポーョスッポロチャーモ"><span>ポーョスッポロチャーモ</span></h1>
        </div>
      </div>


      <div className="profile">
        <h2>Profile</h2>
        <div className="profileContents">
          <img src={profilePic} className="profilePic" />
          <div className="bio">
            <h3>Takita</h3>
            <p>イラストレーター。キャラクターを考えるのが得意で、絵以外にも動画やVRoid、UTAUなどにも触れています。浅く広くに音楽が好き。アイスクリームも同じくらい好き。</p>
            <div className="links">
              <ul>
                <a href="https://twitter.com/takita040"><li className="twitter"><span>Twitter</span></li></a>
                <a href="https://www.pixiv.net/users/2255019"><li><span>Pixiv</span></li></a>
                <a href="https://skeb.jp/@takita040"><li><span>Skeb</span></li></a>
                <a href="https://skima.jp/profile?id=39182"><li><span>Skima</span></li></a>
                <a href="https://takita040.booth.pm/"><li><span>Booth</span></li></a>
                <a href="https://www.youtube.com/channel/UCUorIW_XAPzdZEpr2NVC5Zw"><li><span>YouTube</span></li></a>
              </ul>
            </div>
          </div>
        </div>
      </div>

    <div className="twitter-emb works"style={{paddingBottom:"2rem", margin:"0px auto", width:"100%", display:"grid", justifyItems:"center"}}>
        <blockquote class="twitter-tweet" style={{margin:"0px"}}><p lang="ja" dir="ltr" style={{margin:"0px"}}>直接依頼用の個人向けイラスト料金表をご用意いたしました。<br />様々な用途でご利用できるキャライラストをお描きします～！活動者向けプラン等も用意してみました。<br /><br />無料見積もり、ご質問などお気軽にお声がけください。<br />どうぞよろしくおねがいします！<a href="https://twitter.com/hashtag/%E6%9C%89%E5%84%9F%E4%BE%9D%E9%A0%BC?src=hash&amp;ref_src=twsrc%5Etfw">#有償依頼</a> <a href="https://twitter.com/hashtag/Commision?src=hash&amp;ref_src=twsrc%5Etfw">#Commision</a> <a href="https://t.co/VSzAahUG8g">pic.twitter.com/VSzAahUG8g</a></p>&mdash; Takita ﾎﾞｶﾄﾞｩｵ2チーム参戦 (@takita040) <a href="https://twitter.com/takita040/status/1643196645914578944?ref_src=twsrc%5Etfw">April 4, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
      </div>

      <div className="works">
        <h2>Works</h2>
          <nav>
            <a href="#i1" title="Illust 1" />
            <a href="#i2" title="Illust 2" />
            <a href="#i3" title="Illust 3" />
            <a href="#i4" title="Illust 4" />
            <a href="#i5" title="Illust 5" />
          </nav>
        <div className="works-contents">
          <div className="works-item">
            <div className="illust overlap" id="i1">
             <img src={illust1} alt="First Illustration" />
            </div>
            <div className="disc">
              <h3>白衣の少年</h3>
              <p>mocoさん宛オリジナルキャラクターデザイン</p>
            </div>
          </div>
          <div className="works-item">
            <div className="illust overlap" id="i2">
              <img src={illust2} alt="Second Illustration" />
            </div>
            <div className="disc">
              <h3>赤眼の少年</h3>
              <p>RYOさん宛てオリジナルキャラクターデザイン <a href="https://twitter.com/takita040/status/1388717301562634246">Twitter</a></p>
            </div>
          </div>
          <div className="works-item">
            <div className="illust overlap" id="i3">
              <img src={illust3} alt="Third Illustration" />
            </div>
            <div className="disc">
              <h3>主人公たち</h3>
              <p>原神ファンアート <a href="https://twitter.com/takita040/status/1399609124145418244">Twitter</a></p>
            </div>
          </div>
          <div className="works-item">
            <div className="illust overlap" id="i4">
              <img src={illust4} alt="Third Illustration" />
            </div>
            <div className="disc">
              <h3>スクロース</h3>
              <p>原神ファンアート <a href="https://twitter.com/takita040/status/1350493576627556352">Twitter</a></p>
            </div>
          </div>
          <div className="works-item">
            <div className="illust overlap" id="i5">
              <img src={illust5} alt="Third Illustration" />
            </div>
            <div className="disc">
              <h3>行秋</h3>
              <p>原神ファンアート <a href="https://twitter.com/takita040/status/1325370064455962624">Twitter</a></p>
            </div>
          </div>
        </div>
      </div>


      {/* <div className="background"></div> */}

  
    </main>
  )
  
}

export default IndexPage
